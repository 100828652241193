import Swiper from 'swiper';
import { EffectFade, EffectCards, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';

export default class CardSwiper {
    constructor() {
        const wrapper = document.querySelectorAll('.cards--swiper-wrapper');

        if(!wrapper.length) {
            return;
        }

        wrapper.forEach((wrap) => {
            const CardSwiper = new Swiper('.cards-swiper', {

                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev"
                },

                effect: "cards",
                // slidesPerView: 1,
                // loop: true,
                initialSlide: 2,

                pagination: {
                    el: ".swiper-pagination",
                    clickable: true
                },

                modules: [EffectCards, Pagination, Navigation],
            })
        })
    }
}